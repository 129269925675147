 /* cores globais */
 :root {
  --cor-primaria: #c5c84e;
  --cor-secundaria: #2c5860;
  --cor-terciaria: #63c3d1;
  --bg-logo: #2b737e;
  --bg-login: #343a40;
  --text-menu-left: #FFF;
  --text-login: #FFF;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
