.itemdia {
  width: 40px;
  height: 30px;
}

.ano {
  border: solid 1px;
  border-color: rgba(255, 255, 255, 0);
  padding: 5px;
}

.ano:hover {
  border: white solid 1px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}

.ano-change:hover {
  color: #4f5b69;
  border-radius: 100%;
  background-color: white;
}

.dia-change {
  border: solid 0.5px;
  width: 30px;
  border-color: rgba(255, 255, 255, 0);
}

.dia-change:hover {
  border-radius: 10%;
  border: white solid 0.5px;
  width: 30px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transition: 0.5s;
}

.dia-change.active {
  background-color: white;
}
