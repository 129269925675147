body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:link {
  text-decoration: none;
}

h1 {
  font: Raleway;
  font-weight: bold;
  font-size: 30px;
}

h2 {
  font: Raleway;
  font-weight: bold;
  font-size: 26px;
}

h3 {
  font: Raleway;
  font-weight: bold;
  font-size: 21px;
}

h3 {
  font: Raleway;
  font-weight: bold;
  font-size: 18px;
}

p,
label,
i,
label {
  font: Raleway;
  font-size: 14px;
}

button {
  font-family: Raleway;
}

.text-bege {
  color: var(--cor-primaria);
}

.text-primary {
  color: var(--cor-primaria);
}

.btn-bege {
  color: #fff;
  background-color: var(--cor-primaria);
  border-color: var(--cor-primaria);
}

.btn-bege:hover {
  color: #fff;
  background-color: var(--cor-primaria);
  border-color: var(--cor-primaria);
}

.btn-bege:focus,
.btn-bege.focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 186, 72, 0.5);
}

.btn-bege.disabled,
.btn-bege:disabled {
  color: #fff;
  background-color: var(--cor-primaria);
  border-color: var(--cor-primaria);
}

.btn-bege:not(:disabled):not(.disabled):active,
.btn-bege:not(:disabled):not(.disabled).active,
.show > .btn-bege.dropdown-toggle {
  color: #fff;
  background-color: var(--cor-primaria);
  border-color: var(--cor-primaria);
}

.btn-bege:not(:disabled):not(.disabled):active:focus,
.btn-bege:not(:disabled):not(.disabled).active:focus,
.show > .btn-bege.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 186, 72, 0.5);
}

.bg-bege {
  background-color: var(--cor-primaria) !important;
}

.bg-bege-opacity {
  background-color: var(--cor-primaria) !important;
  opacity: 0.85;
}

a.bg-bege:hover,
a.bg-bege:focus,
button.bg-bege:hover,
button.bg-bege:focus {
  background-color: var(--cor-primaria) !important;
}

.btn-primary {
  color: #fff;
  background-color: var(--cor-primaria);
  border-color: var(--cor-primaria);
}

.btn-primary:hover {
  color: #fff;
  background-color: var(--cor-primaria);
  border-color: var(--cor-primaria);
}

.btn-outline-primary {
  color: var(--cor-primaria);
  border-color: var(--cor-primaria);
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--cor-primaria);
  border-color: var(--cor-primaria);
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--cor-primaria);
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--cor-primaria);
  border-color: var(--cor-primaria);
}

.btn-outline-bege {
  color: var(--cor-primaria);
  border-color: var(--cor-primaria);
}

.btn-outline-bege:hover {
  color: #fff;
  background-color: var(--cor-primaria);
  border-color: var(--cor-primaria);
}

.btn-outline-bege:focus,
.btn-outline-bege.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-bege.disabled,
.btn-outline-bege:disabled {
  color: var(--cor-primaria);
  background-color: transparent;
}

.btn-outline-bege:not(:disabled):not(.disabled):active,
.btn-outline-bege:not(:disabled):not(.disabled).active,
.show > .btn-outline-bege.dropdown-toggle {
  color: #fff;
  background-color: var(--cor-primaria);
  border-color: var(--cor-primaria);
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 181, 193, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--cor-primaria);
  border-color: var(--cor-primaria);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--cor-primaria);
  border-color: var(--cor-primaria);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 181, 193, 0.5);
}

.vertical-center {
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.vertical-center-80 {
  min-height: 80%;
  min-height: 80vh;
  display: flex;
  align-items: center;
}

.login-mobioh {
  background-image: url("../src/assets/images/bg-login.png");
}

.color-date-picker {
  color: #666;
}

.icon-close-navbar-top {
  transform: translateY(-35px);
}

.btn-sanduiche {
  border-radius: 0;
  top: 0;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.bg-container-mobioh {
  background: #f5f5f5 0% 0% no-repeat padding-box;
}

.bg-primary {
  background-color: var(--cor-primaria) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-event-none {
  pointer-events: none;
}

.cursor-move {
  cursor: move;
}

.border-rounded-25 {
  border-radius: 25px;
}

.bg-danger {
  background: #ec5b5b 0% 0% no-repeat padding-box !important;
}

.overflow-visible {
  overflow: visible !important;
}

.border-primary-full {
  border: var(--cor-primaria) solid 3px;
  border-radius: 5px;
}

@keyframes fa-blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
.fa-blink {
  -webkit-animation: fa-blink 2s linear infinite;
  -moz-animation: fa-blink 2s linear infinite;
  -ms-animation: fa-blink 2s linear infinite;
  -o-animation: fa-blink 2s linear infinite;
  animation: fa-blink 2s linear infinite;
}

.fonte-50 {
  font-size: 50px;
}

.fonte-70 {
  font-size: 70px;
}

.fonte-100 {
  font-size: 100px;
}

.fonte-150 {
  font-size: 150px;
}

.pulse {
  animation: crescendo 1s alternate infinite ease-in;
}

@keyframes crescendo {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.height-100 {
  height: 100px;
}

.height-150 {
  height: 150px;
}

.height-50 {
  height: 50px;
}

.height-40 {
  height: 40px;
}

.font-bold {
  font-weight: bold;
}

.tachado {
  text-decoration: line-through;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.pulsar {
  animation: pulsar 2s;
  animation-iteration-count: 1;
}

@keyframes pulsar {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.8, 0.8);
  }
  50% {
    transform: scale(1, 1);
  }
  75% {
    transform: scale(0.8, 0.8);
  }
  100% {
    transform: scale(1, 1);
  }
}

.bg-success {
  background-color: #8fd19e !important;
}

.opacity-7 {
  opacity: 0.7;
}

.pointer-help {
  cursor: help;
}

.fa-2x {
  font-size: 1.4rem !important;
}
