[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
.column {
  height: 150px;
  width: 150px;
  float: left;
  border: 2px solid #666666;
  background-color: #ccc;
  margin-right: 5px;
  -webkit-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px #000;
  -ms-box-shadow: inset 0 0 3px #000;
  box-shadow: inset 0 0 3px #000;
  text-align: center;
  cursor: move;
}
.column header {
  color: #fff;
  text-shadow: #000 0 1px;
  box-shadow: 5px;
  padding: 5px;
  background: -moz-linear-gradient(
    left center,
    rgb(0, 0, 0),
    rgb(79, 79, 79),
    rgb(21, 21, 21)
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, rgb(0, 0, 0)),
    color-stop(0.5, rgb(79, 79, 79)),
    color-stop(1, rgb(21, 21, 21))
  );
  background: -webkit-linear-gradient(
    left center,
    rgb(0, 0, 0),
    rgb(79, 79, 79),
    rgb(21, 21, 21)
  );
  background: -ms-linear-gradient(
    left center,
    rgb(0, 0, 0),
    rgb(79, 79, 79),
    rgb(21, 21, 21)
  );
  border-bottom: 1px solid #ddd;
  -webkit-border-top-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -ms-border-radius-topleft: 10px;
  border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -ms-border-top-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  border-top-right-radius: 10px;
}

.over {
  transform: scale(0.85, 0.95);
  transition: 0.5s;
  box-sizing: border-box;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.opacity-4 {
  opacity: 0.4;
}

.dragdropenter {
  display: block;
  opacity: 1;
  transition: 1s;
  transform: translate(-25px, 25px);
}

.dragdropleave {
  display: none;
  opacity: 0;
  transition: 1s;
}
