.mg-top-alert {
  margin-top: 11px;
}

.alert-mobioh {
  width: 100%;
  height: 50px;
  position: absolute;
  z-index: 100;
  top: 0;
  opacity: 0;
  visibility: hidden;
  align-items: left;
}

.alert-mobioh-active {
  transition: 1s;
  opacity: 1;
  visibility: visible;
}
