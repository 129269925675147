.timeline {
  height: 4px;
  background-color: #ccc;
  margin-top: 20%;
}

.timeline-progress {
  width: 0;
  height: 100%;
  background-color: orange;
}

.timeline-items {
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -12px;
  display: flex;
  justify-content: space-evenly
}

.timeline-item {
  position: relative;
}

.timeline-item::before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: #ccc;
  display: block;
  border-radius: 100%;
}

.timeline-label {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ddd;
  width: 220px;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  transform: rotate(-45deg) translateX(40%) translateY(-300%);
}

.timeline-content {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ddd;
  width: 80px;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
}

.timeline-content::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #ddd;
  display: block;
  position: absolute;
  top: -8px;
  margin-left: -2px;
  left: 50%;
  transform: rotate(-45deg) translateX(-50%);
}

.timeline-item.active::before {
  background-color: orange;
}

.timeline-item.active .timeline-label {
  color: #fff;
  background-color: orange;
}

.timeline-item.active .timeline-label::before {
  background-color: orange;
}

.timeline-item.active .timeline-content {
  color: #fff;
  background-color: orange;
}

.timeline-item.active .timeline-content::before {
  background-color: orange;
}
