.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
    position:fixed;
    background: white;
    border-radius: 5px;
    width: 50%;
    height: auto;
    top:50%;
    left:50%;
    transition: 1s;
    transform: translate(-50%,-50%);
  }
