#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  transition: 0.5s;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}

.brand {
  background: transparent linear-gradient(90deg, var(--bg-logo) 0%, #2d2e32 100%) 0% 0%
    no-repeat padding-box;
  line-height: 40px;
  display: block;
  text-align: center;
  font-size: 14px;
}

/** INPUT ICON */

.input-wrapper {
  display: inline-block;
  position: relative;
  line-height: 12px;
}
.input-wrapper::after {
  font-family: "FontAwesome";
  content: "\f002";
  position: absolute;
  right: 6px;
  top: 8px;
  color: white;
  opacity: 0.5;
  cursor: pointer;
}

.input-menu-left {
  background-color: rgb(255, 255, 255, 0.1);
  color: white;
}

.input-menu-left::placeholder {
  color: white;
  opacity: 0.8;
}