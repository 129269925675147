.card-header-primary {
    background: #4a8f99 0% 0% no-repeat padding-box;
    border: solid 1px #4a8f99;
    color: white;
    opacity: 0.90;
    margin-top: 10px

}

.card-footer-primary {
    background: #54a3ad 0% 0% no-repeat padding-box;
    border: solid 1px #54a3ad;
    color: white;
    font-size: 10px;
    opacity: 0.50;
}

.card-body-primary {
    background: var(--cor-primaria) 0% 0% no-repeat padding-box;   
    color: white;
    opacity: 1;
}

.card-header-bege {
    background: #888931 0% 0% no-repeat padding-box;
    color: white;
    border: solid 1px #888931;
    opacity: 0.90;
    margin-top: 10px

}

.card-footer-bege {
    background: #d4d653 0% 0% no-repeat padding-box;
    color: white;
    border: solid 1px #d4d653;
    font-size: 10px;
    opacity: 0.80;
}

.card-body-bege {
    background: var(--cor-primaria) 0% 0% no-repeat padding-box;  
    color: white;  
    opacity: 1;
}

.card-header-default {
    background: #000000 0% 0% no-repeat padding-box;
    border: solid 1px #000000;
    color: white;
    opacity: 0.90;
    margin-top: 10px;

}

.card-footer-default {
    background: #2D2E32 0% 0% no-repeat padding-box;
    border: solid 1px #2D2E32;
    color: white;
    font-size: 10px;
    opacity: 0.70;
}

.card-body-default {
    background: #2D2E32 0% 0% no-repeat padding-box;
    color: white;
    opacity: 1;
}

.card-container {
    border: none;
}

.card-container:hover {
    opacity: 0.8;
    box-shadow: 3px 8px 5px 0px #ccc;
    transition: 0.5s;
    transform: translate(0px, -4px);    
    cursor: pointer
}