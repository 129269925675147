.inputgrouptext {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.formcontrol {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search-table {
  cursor: pointer;
}

.input-search-hidden {
  width: 0px;
  opacity: 0;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}

.input-search-active {
  width: 100%;
  opacity: 1;
}

.inputsearch {
  width: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: 0.7s ease-in-out;
  transition: 0.7s ease-in-out;
}

.searchbar.active > .inputsearch {
  pointer-events: auto;
  opacity: 1;
  width: 100%;
}

.translateXmenos15 {
  transform: translateX(-15px);
}

.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type=file] {
  position: absolute;
  opacity: 0;
}

.btn-file label {
  padding: 0;
  margin: 2px;
}